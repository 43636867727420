<template>
  <v-row no-gutters justify="center">
    <v-col cols="12" class="text-center mt-6">
      <v-img width="100" class="mx-auto" src="@/assets/pictures/roote-logo.png" alt="Logo Roote"/>
    </v-col>
    <v-col cols="12" class="my-3">
      <v-row no-gutters justify="center">
        <router-link class="text-decoration-none mr-1 font-weight-bold" style="color: black !important;" :to="{name: 'Legal'}">{{$t('common.footer.legal')}}</router-link> -
        <router-link class="text-decoration-none ml-1 font-weight-bold" style="color: black !important;" :to="{name: 'PrivacyPolicy'}">{{$t('common.footer.privacy')}}</router-link>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>